<template>
  <div>
    <slot name="header" />
    <div
      v-if="storingDraft"
      class="container mx-auto max-w-sm">
      <div class="loader mx-auto my-20" />
    </div>
    <div
      v-if="!storingDraft"
      class="container mx-auto max-w-sm">
      <transition
        name="fade"
        mode="out-in">
        <div
          class="my-12"
          v-if="state === 'initial'"
          key="initial">
          <div class="pb-2 mt-10">
            <span class="block text-center mb-4">
              Trykk på knappen under for å verifisere deg med BankID. Du vil få forespørsel om å gjøre dette for LEGEVISITT AS (som er oss). 
            </span>
          </div>
          <form @submit.prevent="init">
            <error-message v-if="error">
              {{ error }}
            </error-message>
            <default-button
              v-else
              :loading="loading"
              :disabled="submitting"
              @click.prevent.native="init"
              primary
              class="w-full">
              Verifiser meg
            </default-button>
          </form>
        </div>
        <div
          class="my-12"
          v-else-if="state === 'complete'"
          key="complete">
          <div class="p-3 bg-green-100 text-gray-800 border border-green-300 rounded-sm mb-6 font-semibold text-sm text-center">
            BankID er bekreftet. 
          </div>
        </div>
        <div
          class="my-12"
          v-else-if="state === 'aborted'"
          key="aborted">
          <div>
            <error-message>
              BankID signeringen ble avbrutt.
            </error-message>
            <div class="text-center">
              <a
                href="#"
                @click.prevent="restart"
                class="underline text-sm">Klikk her for å prøve igjen</a>
            </div>
          </div>
        </div>
        <div
          class="my-12"
          v-else-if="state === 'error'"
          key="aborted">
          <error-message class="p-3 bg-red-100 text-gray-800 border border-red-300 rounded-sm mb-6 font-semibold text-sm text-center">
            En feil oppstod ved signering. Klikk under for å prøve på nytt eller kontakt oss hvis feilen vedvarer.
          </error-message>
          <div class="text-center">
            <a
              href="#"
              @click.prevent="restart"
              class="underline text-sm">Klikk her for å prøve igjen</a>
          </div>
        </div>
      </transition>
    </div>
    <slot :is-valid="isValid" />
  </div>
</template>

<script>
import axios from "axios"

export default {
  props: {
    data: {
      type: Object,
      default() {
        return {}
      }
    },

    formId: {
      type: Number,
      default: null
    },

    draftId: {
      type: String,
      default: null
    },

    formData: {
      type: Object,
      default () {
        return {}
      }
    },
    setFormData: {
      type: Function,
      default () {
        return () => {}
      }
    },
    next: {
      type: Function,
      default: () => {}
    },
    storeDraftOrder: {
      type: Function,
      default: () => {}
    }
  },

  data() {
    return {
      authUrl: null,
      loading: false,
      error: false,
      state: "initial",
      slug: null,
      submitting: false,
      storingDraft: false
    }
  },

  async created() {
    this.slug = this.$route.params.slug
    if(this.$route.query && this.$route.query.success == 1 && this.$route.query.session_id)
    {
        const sessionId = this.$route.query.session_id
        const isActive = await this.hasActiveAuthentication(sessionId)
        if(isActive)
        {
          this.bankIdSessionId = this.$route.query.session_id
          this.setFormData("bankIdSessionId", this.bankIdSessionId)
          this.state = "complete"
        }
    }

    if(this.$route.query && this.$route.query.error == 1)
    {
      this.state = "error"
    }

    if(this.$route.query && this.$route.query.aborted == 1)
    {
      this.state = "aborted"
    }
  },

  methods: {
    init() {
      this.loading = true
      this.submitting = true
      // 1. Persist the draft order (automatic now), get its id
      // 2. Start a bankid session
      // 3. In our success handler:
      //    a) Get session id (laravel session)
      //    b) Find draft order on this session
      //    c) Redirect to spa (slug)
      axios.post("/api/bank-id/redirect", {slug: this.slug, draft_order_id: this.draftId})
        .then(({ data }) => {
          this.authUrl = data.auth_url
        })
        .catch(() => {
          this.error = true
        })
        .finally(()=> {
          this.loading = false
          this.submitting = false
        })
    },

    hasActiveAuthentication : async function (sessionId)
    {
      this.loading = true
      try {
        const response = await axios.get("/api/bank-id/exists", { params: {"sessionId" : sessionId}})
        const isActive = response.data.is_logged_in
        return isActive
      }
      catch(error)
      {
        this.error = true
      }
      finally {
        this.loading = false
      }
    },

    restart()
    {
      this.loading = false
      this.submitting = false
      this.state = "initial"
    }
  },

  computed: {
    isValid() {
      return this.state === "complete"
    }
  },

  watch: {
    authUrl(newUrl)
    {
        if(newUrl !== null)
        {
            window.location.href = newUrl
        }
    }
  }
}
</script>
